import React, { useEffect, useRef } from 'react'
import "./style.css"

import { Image, Space } from 'antd';



export default function Home() {
  const audioRef = useRef(null);


  const mImg = (src, scr2, className) => {
    return <Image
      className={className}
      width={125}
      src={src}
      preview={{
        imageRender: () => (<img src={scr2} loading="lazy" alt="" className='c-c1-img-preview1' />),
        toolbarRender: () => null,
      }}
    />
  }
  const mImgCen = (src, scr2, className) => {
    return <Image

      className={className}
      width={325}
      src={src}
      preview={{
        imageRender: () => (<img src={scr2} loading="lazy" alt="" className='c-c1-img-preview2' />),
        toolbarRender: () => null,
      }}
    />
  }


  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play()
        .catch(error => console.error('Error al intentar reproducir el audio:', error));
    }
  };

  return (
    <div class="home" onMouseDown={handlePlay}>
      <audio ref={audioRef} loop> {/* Atributo loop añadido aquí */}
        <source src="media/home.mp3" type="audio/mp3" />
        Tu navegador no soporta el elemento de audio.
      </audio>
      <section class="header">
        <div class="h-contenedor">
          <img src="images/LogoMenteDorada.png" loading="lazy" alt="" class="image-7" />
          <nav className="h-menu">
            <a className="h-menu-content-seleccionado h-menu-text">Inicio</a>
            <a className="h-menu-content h-menu-text">Servicios</a>
            <a className="h-menu-content h-menu-text">Recursos</a>
            <a className="h-menu-content h-menu-text">Blog</a>
            <a className="h-menu-content h-menu-text">Tienda</a>
            <a className="h-menu-content h-menu-text">Contacto</a>
          </nav>
        </div>
      </section>
      <section class="content">
        <div class="c-content1">
          <h1 class="c-titulo1">Mente Dorada</h1>
          <h1 class="c-subtitulo1">Institución de Salud Mental </h1>
          <div class="c-c1-imgs">
            <div class="c-c1-imgs-izq">
              {mImg("images/VicktorE.png", "images/VIKTORE_B.png", "c-c1-img")}
              {mImg("images/Albert_Ellis.png", "images/ALBERT_ELLIS_B.png", "c-c1-img")}
            </div>
            <div class="c-c1-imgs-cen">
              {mImgCen("images/MarcoKim-1.png", "images/KIM.jpg", "c-c1-img-cen")}
            </div>
            <div class="c-c1-imgs-der">
              {mImg("images/AARONE.png", "images/AARONE_B.png", "c-c1-img")}
              {mImg("images/SIGMUN.png", "images/SIGMUND_B.png", "c-c1-img")}
            </div>
          </div>
          <div class="c-c1-textos">
            <div class="text-block">Nace de una partede mi misión en esta vida la cual es contribuir en la sanación de la mente y elcorazón de las personas. </div>
            <div class="text-block-2">&quot;Mi mayor éxito enla vidaserá devolverle elbrillo a unos ojos tristes”</div>
            <div class="text-block-3">Kimberly C. Gómez J.</div>
          </div>
          <div class="c-separador--boton">
            <div class="c-separador-text">VER SERVICIOS</div>
          </div>
        </div>
        <div class="c-content2">
          <Image

            className={"image-10"}
            width={325}
            src={"images/Carl_Gustav_Jung.png"}
            preview={{
              imageRender: () => (<img src={"images/CarlB.jpg"} loading="lazy" alt="" className='c-c1-img-preview2' />),
              toolbarRender: () => null,
            }}
          />
          <div class="c-c2-cita1">
            <div class="c-c2-ct1-text1-content">
              <p class="c-c2-ct1-text1">&quot;Hasta que lo inconsciente no se haga consciente, el subconsciente seguirá dirigiendo tu vida, y tú le llamarás destino&quot;. </p>
            </div>
            <div class="c-c2-ct1-text2">Carl Gustav Jung</div>
          </div>
        </div>
        <div class="c-content3">
          <div class="c-separador">
            <div class="c-separador-text-n">TESTIMONIOS</div>
          </div>
          <div class="c-c3-testimonios">
            <div class="c-c3-testimonio">
              <div class="c-c3-test-text">Paciente en Psicoterapiadesde 2022</div>
              <p class="c-c3-test-text">“Gracias por tus palabras, por las técnicas y por todo lo que me has enseñado, estoyencontrando en mí la paz y la felicidad que siempre busqué”. </p>
              <div class="c-c3-test-text">♛Angie– 42 años.</div>
            </div><img src="images/Pilar_Testimonio.png" loading="lazy" alt="" class="c-c3-img-pilar" />
            <div class="c-c3-testimonio">
              <div class="c-c3-test-text">Paciente en Psicoterapiadesde 2022</div>
              <p class="c-c3-test-text">“Muchas graciaspor ser tan buena psicóloga”.</p>
              <div class="c-c3-test-text">♛Ale – 18 años.</div>
            </div><img src="images/Pilar_Testimonio.png" loading="lazy" alt="" class="c-c3-img-pilar" />
            <div class="c-c3-testimonio">
              <div class="c-c3-test-text">Paciente en Orientación Vocacional 1:1 - 2022</div>
              <p class="c-c3-test-text">“Me has ayudado a encontrarmi propósito y mi misión de vida, ahora mi vida tiene sentido, estoy tanagradecido por haberte encontrado”.</p>
              <div class="c-c3-test-text">♚Carlos – 29 años.</div>
            </div>
          </div>
        </div>
        <div class="c-separador--boton">
          <div class="c-separador-text">IR A SERVICIOS</div>
        </div>
      </section>
      <section class="footer"><img src="images/Separador_Footer.png" loading="lazy" sizes="(max-width: 1439px) 72vw, 1000.0000610351562px" srcset="images/Separador_Footer-p-500.png 500w, images/Separador_Footer-p-800.png 800w, images/Separador_Footer-p-1080.png 1080w, images/Separador_Footer-p-1600.png 1600w, images/Separador_Footer-p-2000.png 2000w, images/Separador_Footer.png 2400w" alt="" class="image-12" />
        <div class="f-icons">
          <a href="https://www.instagram.com/mentedorada_psicologia/">
            <img src="images/ImgIG.png" loading="lazy" alt="" class="f-icon" />
          </a>
          <a href="https://www.facebook.com/mentedoradapsicologia">
            <img src="images/ImgFB.png" loading="lazy" alt="" class="f-icon" />
          </a>
          <a href="https://twitter.com/_mentedorada">
            <img src="images/ImgX.png" loading="lazy" alt="" class="f-icon" />
          </a>
          <a href="https://www.pinterest.com.mx/mentedoradapsicologia/">
            <img src="images/ImgPint.png" loading="lazy" alt="" class="f-icon" />
          </a>
        </div>
        <div class="f-text-menu">
          <div class="f-text-menu-izq">
            <p class="f-parrafo">Sé la primera y afortunada persona en enterarte de las promociones en todos los servicios, mantente a la vanguardia con el contenido en redes e inspírate y aprende con las nuevas  notas del Blog.</p>
            <figure class="f-div-suscribirse">
              <div class="text-block-9">✉</div>
              <div class="text-block-7"><strong class="bold-text">Suscribirse</strong></div>
            </figure>
          </div>
          <div class="f-links">
            <div class="f-text-links-seleccionado">Inicio</div>
            <div class="f-text-links">Servicios</div>
            <div class="f-text-links">Recursos</div>
            <div class="f-text-links">Blog</div>
            <div class="f-text-links">Tienda</div>
            <div class="f-text-links">Contacto</div>
          </div>
        </div>
      </section><img src="images/iconoWhats.png" loading="lazy" alt="" class="image-11" />
    </div>
  )
}
