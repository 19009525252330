import Home from './frames/home/Home'


function App() {
  return (
    <Home/>
  );
}

export default App;
